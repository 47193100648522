import React from 'react';
import { Link } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import { SEO } from 'components/shared/seo/seo';
import Intro from 'components/pages/home/intro';
import HowItWorks from 'components/pages/home/how-it-works';
import LeadSystem from 'components/pages/home/lead-system';
import Competitors from 'components/pages/home/competitors';
import Companies from 'components/pages/home/companies';
import Banner from 'components/pages/home/banner';
import Feedbacks from 'components/pages/home/feedbacks';
import ClaimYourCity from 'components/pages/home/claim-your-city';

const IndexPage = () => (
  <LayoutMain>
    <SEO />
    <Intro />
    <HowItWorks />
    <LeadSystem />
    <Competitors />
    <Companies />
    <Banner />
    <Feedbacks />
    <ClaimYourCity />
  </LayoutMain>
);

export default IndexPage;
