import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import styles from './feedbacks.module.scss';
import Stroke from './stroke.inline.svg';

const Feedbacks = props => {
  const {
    avatar1: {
      childImageSharp: { fixed: avatar1Fixed },
    },
    avatar2: {
      childImageSharp: { fixed: avatar2Fixed },
    },
  } = useStaticQuery(graphql`
    query {
      avatar1: file(relativePath: { eq: "home/avatar-1.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      avatar2: file(relativePath: { eq: "home/avatar-2.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  return (
    <section ref={ref} className="section-default">
      <div className="container">
        <h2 className={`heading md center ${styles.title}`}>
          Hear what agents have to say
        </h2>
        <div
          className={`columns is-multiline is-mobile ${
            styles.feedbackWrapper
          } ${inView && styles.feedbackWrapper_animated}`}
        >
          <div className="column is-5-desktop is-7-tablet is-7-mobile">
            <div className={styles.feedbackFeatured}>
              <p className={styles.description}>
                As a new realtor, getting my own leads was something that scared
                me in the beginning. I was fortunate to find Bam Leads and they
                helped me close an extra 12 deals my first year which are now a
                source of referrals for me.
              </p>
              <div className={styles.info}>
                <div className={styles.avatar}>
                  <Img fixed={avatar1Fixed} />
                </div>
                <div className={styles.infoRight}>
                  <div className={styles.name}>
                    Christina B.
                    <Stroke className={styles.strokePurple} />
                  </div>
                  <div className={styles.company}>RE/MAX Elite</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4-desktop is-5-tablet is-5-mobile">
            <div className={styles.feedback}>
              <p className={styles.description}>
                I'm so happy I found such a simple way to get leads online. Bam
                Leads does such a better job at getting me leads than running
                ads directly to my website was doing.
              </p>
              <div className={styles.info}>
                <div className={styles.infoRight}>
                  <div className={styles.name}>
                    Kennedy O.
                    <Stroke className={styles.strokeYellow} />
                  </div>
                  <div className={styles.company}>Keller Williams Realty</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-3-desktop is-6-tablet is-6-mobile">
            <div className={styles.feedback}>
              <p className={styles.description}>
                I have 5 new buyers I'm working with and 4 of them are listing
                with me. Being able to instantly follow up with my leads has
                played a big part in this..
              </p>
              <div className={styles.info}>
                <div className={styles.infoRight}>
                  <div className={styles.name}>
                    Jason D.
                    <Stroke className={styles.strokeGreen} />
                  </div>
                  <div className={styles.company}>Coldwell Banker</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4-desktop is-6-tablet is-6-mobile">
            <div className={styles.feedback}>
              <p className={styles.description}>
                I have closed 6 deals in 2 months using Bamleads. I will be
                increasing my marketing spend this month to see even better
                results.
              </p>
              <div className={styles.info}>
                <div className={styles.infoRight}>
                  <div className={styles.name}>
                    David D.
                    <Stroke className={styles.strokeYellow} />
                  </div>
                  <div className={styles.company}>Century 21</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-3-desktop is-5-tablet is-5-mobile">
            <div className={styles.feedback}>
              <p className={styles.description}>
                After 6 months I've closed over 20 leads and I have over 300 in
                the system to continue to follow up with.
              </p>
              <div className={styles.info}>
                <div className={styles.infoRight}>
                  <div className={styles.name}>
                    Mark K.
                    <Stroke className={styles.strokeGreen} />
                  </div>
                  <div className={styles.company}>Berkshire Hathaway</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-5-desktop is-7-tablet is-7-mobile">
            <div className={styles.feedbackFeatured}>
              <p className={styles.description}>
                I've been in this business for over 10 years and added Bam Leads
                as a new lead source this year. I've received around 500 leads
                in less than a year and as a result reached a higher club level
                this year.
              </p>
              <div className={styles.info}>
                <div className={styles.avatar}>
                  <Img fixed={avatar2Fixed} />
                </div>
                <div className={styles.infoRight}>
                  <div className={styles.name}>
                    Samil C.
                    <Stroke className={styles.strokePurple} />
                  </div>
                  <div className={styles.company}>
                    Sotheby's International Realty
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedbacks;
