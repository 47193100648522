import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import styles from './competitors.module.scss';

const Competitors = props => {
  const {
    city: {
      childImageSharp: { fluid: cityFluid },
    },
  } = useStaticQuery(graphql`
    query {
      city: file(relativePath: { eq: "city.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  return (
    <section ref={ref} className={`section-default ${styles.wrapper}`}>
      <div className={`container ${styles.container}`}>
        <h2 className={`heading md ${styles.title}`}>
          Why agents choose our service
        </h2>
        <div
          className={`columns is-multiline is-mobile ${styles.cols} ${inView &&
            styles.animatedCols}`}
        >
          <div className="column is-3-desktop is-half-tablet is-half-mobile">
            <div className={styles.number}>01</div>
            <h3 className={styles.featureTitle}>Quality of leads</h3>
            <p className={styles.description}>
              The lead funnel is optimized for Google. People searching for 
              your services this very second. Say goodbye to the 
             "What is my house worth", low quality leads. Your leads are 
              expecting your call and are excited to hear from you. 
            </p>
          </div>
          <div className="column is-3-desktop is-half-tablet is-half-mobile">
            <div className={styles.number}>02</div>
            <h3 className={styles.featureTitle}>Personalized</h3>
            <p className={styles.description}>
              This is not just another generic lead funnel. This is a personalized
              lead system where your lead is introduced to you and has been given 
              a face to your name. This increases conversions. 
            </p>
          </div>
          <div className="column is-3-desktop is-half-tablet is-half-mobile">
            <div className={styles.number}>03</div>
            <h3 className={styles.featureTitle}>Battle Tested</h3>
            <p className={styles.description}>
              We've spent millions in advertising to optimize the lead system. 
              While you can personalize and brand it, we limit what you
              change because our design, as is, provides the highest conversion
              rates. Simple process, extraordinary results.
            </p>
          </div>
          <div className="column is-3-desktop is-half-tablet is-half-mobile">
            <div className={styles.number}>04</div>
            <h3 className={styles.featureTitle}>Smart Marketing</h3>
            <p className={styles.description}>
              Leave it to us to manage your account structure, 
demographic optimization, keyword research, bid & position management, geo-targeting, dayparting & hour of day bidding, 
negative keyword management, ad copy testing, A/B testing, SKAG approach & more. 
            </p>
          </div>
        </div>
      </div>
      <Img
        className={styles.city}
        style={{ position: 'absolute' }}
        fluid={cityFluid}
      />
    </section>
  );
};

export default Competitors;
