import { useEffect, useState } from 'react';

// local helper
const awaitGlobalVariable = (variable, cb) => {
  if (typeof window[variable] !== 'undefined') {
    cb();
  } else {
    setTimeout(() => awaitGlobalVariable(variable, cb), 100);
  }
};

const useGlobalVar = variable => {
  const [globalVarIsAvailable, setGlobalVarIsAvailable] = useState(false);
  useEffect(() => {
    if (typeof window === 'undefined' || !variable) return;
    if (typeof window[variable] !== 'undefined') {
      setGlobalVarIsAvailable(true);
    } else {
      awaitGlobalVariable(variable, () => setGlobalVarIsAvailable(true));
    }
  }, [variable]);
  return {
    globalVarIsAvailable,
  };
};

export default useGlobalVar;
