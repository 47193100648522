import React, { useEffect, useState } from 'react';
import { Link as AnchorLink } from 'react-scroll';
import useLottie from 'utils/use-lottie';
import { useInView } from 'react-intersection-observer';
import styles from './intro.module.scss';

const Intro = () => {
  const [animationVisibilityRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.6,
  });

  const [animationReady, setAnimationReady] = useState(false);

  const { animation, animationRef } = useLottie({
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/animations-data/intro-animation.json',
  });

  useEffect(() => {
    if (animation) {
      animation.addEventListener('loaded_images', () => {
        setAnimationReady(true);
      });
    }
  }, [animation]);

  useEffect(() => {
    if (inView && animation && animationReady) {
      animation.play();
    }
  }, [animationReady, inView]);

  return (
    <section className={`section-default ${styles.wrapper}`}>
      <div className="container">
        <div className="columns is-multiline">
          <div className={`column is-12 is-6-desktop ${styles.left}`}>
            <h1 className={`heading ${styles.title}`}>
              Generate high intent buyer & seller leads on autopilot.
            </h1>
            <p className={styles.description}>
              Thousands of realtors are actively using our lead system to capture new, highly motived leads every month.   
            </p>
            <div className={styles.actions}>
              <a
                className="button primary large"
                href="https://app.bamleads.com/users/sign_up"
              >
                Sign up free!
              </a>
              <AnchorLink
                className="button large default"
                to="section-form"
                smooth
                duration={500}
              >
                Claim your city!
              </AnchorLink>
            </div>
            <div className={styles.phone}>or call 855.581.8292</div>
          </div>
          <div
            className={`column is-12 is-5-desktop is-offset-1-desktop  ${styles.right}`}
            ref={animationVisibilityRef}
          >
            <div className={styles.animation} ref={animationRef} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
