import React, { useEffect } from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import styles from './lead-system.module.scss';

const LeadSystem = props => {
  const {
    window: {
      childImageSharp: { fluid: windowFluid },
    },
  } = useStaticQuery(graphql`
    query {
      window: file(relativePath: { eq: "window.png" }) {
        childImageSharp {
          fluid(maxWidth: 602) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const [ref, inView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });
  return (
    <section className={styles.wrapper} ref={ref}>
      <div className="container">
        <h2 className={`heading md center ${styles.title}`}>
          Powerful Lead System
        </h2>
        <p className={`center ${styles.description}`}>
          Take a look at just a few of the features you will find within your lead system. 
        </p>
        <div className={styles.panel}>
          <div className={styles.window}>
            <Img fluid={windowFluid} />
          </div>
          <div
            className={`${styles.features} ${inView &&
              styles.features_animated}`}
          >
            <div className={styles.feature}>
              <div className={styles.check}>
                <div className={styles.checkExternal} />
                <div className={styles.checkInner} />
              </div>
              <div className={styles.info}>
                <h4 className={styles.featureTitle}>CRM</h4>
                <p className={styles.featureDescription}>
                  Keep a record of all of your leads, published automatically.
                </p>
              </div>
            </div>
            <div className={styles.feature}>
              <div className={styles.check}>
                <div className={styles.checkExternal} />
                <div className={styles.checkInner} />
              </div>
              <div className={styles.info}>
                <h4 className={styles.featureTitle}>Advertising</h4>
                <p className={styles.featureDescription}>
                  Spend any amount, let our ad experts handle everything else. 
                </p>
              </div>
            </div>
            <div className={styles.feature}>
              <div className={styles.check}>
                <div className={styles.checkExternal} />
                <div className={styles.checkInner} />
              </div>
              <div className={styles.info}>
                <h4 className={styles.featureTitle}>Conversations</h4>
                <p className={styles.featureDescription}>
                  Have conversations with leads via your dashboard.
                </p>
              </div>
            </div>
            <div className={styles.feature}>
              <div className={styles.check}>
                <div className={styles.checkExternal} />
                <div className={styles.checkInner} />
              </div>
              <div className={styles.info}>
                <h4 className={styles.featureTitle}>Follow-Up</h4>
                <p className={styles.featureDescription}>
                  Instant & automatic follow-up text features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadSystem;
