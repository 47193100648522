import React from 'react';
import styles from './companies.module.scss';
import Kw from './svg/kw.inline.svg';
import Berkshire from './svg/berkshire.inline.svg';
import Remax from './svg/remax.inline.svg';
import Coldwell from './svg/coldwell.inline.svg';
import Sotheby from './svg/sotheby.inline.svg';
import Century from './svg/century.inline.svg';

const Companies = props => (
  <section className="section-default">
    <div className="container">
      <h3 className={`center ${styles.title}`}>
        Top producing realtors from these brokerages depend on Bam Leads
      </h3>
      <div className={styles.companies}>
        <div className={styles.company}>
          <Kw />
        </div>
        <div className={styles.company}>
          <Berkshire />
        </div>
        <div className={styles.company}>
          <Remax />
        </div>
        <div className={styles.company}>
          <Coldwell />
        </div>
        <div className={styles.company}>
          <Sotheby />
        </div>
        <div className={styles.company}>
          <Century />
        </div>
      </div>
    </div>
  </section>
);

export default Companies;
