import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import { Link } from 'gatsby';
import styles from './banner.module.scss';
import Illustration from './illustration.svg';

const Banner = () => (
  <section className={`section-default ${styles.wrapper}`}>
    <div className={`container ${styles.container}`}>
      <Parallax className={styles.parallax} y={[-20, 10]}>
        <img className={styles.parallaxIllustration} src={Illustration} />
      </Parallax>
      <h3 className={`heading sm center ${styles.title}`}>
        Ready to find out more? Have questions?
      </h3>
      <div className={styles.action}>
        <Link className="button secondary large" to="/send-message">
          Send a message
        </Link>

        <span className={styles.phone}>or call 855.581.8292</span>
      </div>
    </div>
  </section>
);

export default Banner;
