import React, { useEffect, useState } from 'react';
import useLottie from 'utils/use-lottie';
import PlacesAutocomplete from 'components/shared/places-autocomplete';
import { useInView } from 'react-intersection-observer';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import axios from 'axios';
import classNames from 'classnames';
import { encode } from 'utils';
import styles from './claim-your-city.module.scss';
import NameIcon from './images/name.inline.svg';
import MailIcon from './images/mail.inline.svg';
import CityIcon from './images/city.inline.svg';
import PhoneIcon from './images/phone.inline.svg';
import HomeIcon from './images/home.inline.svg';

const formValidationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  city: yup.string().required('please select a city from the list'),
  phone: yup.string(),
});

const ClaimYourCity = () => {
  const [animationVisibilityRef, inView] = useInView();
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: formValidationSchema,
  });

  const { animation, animationRef } = useLottie({
    renderer: 'svg',
    loop: true,
    autoplay: false,
    path: '/animations-data/claim-animation.json',
  });

  useEffect(() => {
    if (inView && animation) {
      animation.play();
    }
    if (!inView && animation) {
      animation.pause();
    }
  }, [inView]);

  const onSubmit = async (formData, event) => {
    setLoading(true);
    try {
      const [response] = await Promise.all([
        axios.post('/', encode(formData), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }),
        new Promise(resolve => setTimeout(resolve, 600)),
      ]);
      setLoading(false);
      setSuccessMessage(true);
    } catch (error) {
      setLoading(false);
    }
    event.target.reset();
  };

  return (
    <section className={`section-default ${styles.wrapper}`} id="section-form">
      <div className="container" ref={animationVisibilityRef}>
        <div className={`columns is-multiline ${styles.inner}`}>
          <div className={`column is-12 is-5-desktop ${styles.right}`}>
            <div className={styles.animation} ref={animationRef} />
          </div>
          <div className="column is-12 is-offset-1-desktop is-6-desktop">
            <div className={styles.formContainer}>
              {successMessage ? (
                <div className="message-wrapper">
                  <HomeIcon />
                  <h2 className={`heading md ${styles.messageTitle}`}>
                    Your city is currently available, sign up now and claim it!
                  </h2>
                  <p className={styles.messageText}>
                    Thank you for claiming your city! We only allow one agent per city to
                    use Bam Leads services.
                  </p>
                  <a
                    className="button primary large"
                    href="https://app.bamleads.com/users/sign_up"
                  >
                    Sign up now
                  </a>
                </div>
              ) : (
                <>
                  <h2 className={`heading md ${styles.title}`}>
                    Claim your city
                  </h2>
                  <form
                    name="claim-your-city"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className={styles.formBorder}>
                      <div className={styles.control}>
                        <div className={styles.icon}>
                          <NameIcon />
                        </div>
                        <input
                          className={styles.input}
                          type="text"
                          name="name"
                          placeholder="Name"
                          ref={register({ required: true })}
                        />
                        {errors.name && (
                          <span className={styles.errorInput}>
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                      <div className={styles.control}>
                        <div className={styles.icon}>
                          <MailIcon />
                        </div>
                        <input
                          className={styles.input}
                          type="email"
                          name="email"
                          placeholder="Email"
                          ref={register({ required: true })}
                        />
                        {errors.email && (
                          <span className={styles.errorInput}>
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                      <div className={styles.control}>
                        <div className={styles.icon}>
                          <PhoneIcon />
                        </div>
                        <input
                          className={styles.input}
                          type="tel"
                          name="phone"
                          placeholder="Phone number"
                          ref={register({ required: true })}
                        />
                        {errors.phone && (
                          <span className={styles.errorInput}>
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                      <div
                        className={`${styles.control} ${styles.controlAutocomplete}`}
                      >
                        <div className={styles.icon}>
                          <CityIcon />
                        </div>
                        <PlacesAutocomplete
                          className={styles.input}
                          placeholder="City"
                          types={['(regions)']}
                          onPlaceSelected={place =>
                            setValue('city', place.formatted_address)
                          }
                        />
                        {errors.city && (
                          <span className={styles.errorInput}>
                            {errors.city.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <input
                      type="hidden"
                      name="city"
                      ref={register({
                        name: 'city',
                        type: 'text',
                        required: true,
                      })}
                    />
                    <input
                      type="hidden"
                      name="form-name"
                      value="claim-your-city"
                      ref={register()}
                    />
                    <button
                      className={`button primary large full ${classNames({
                        loading,
                      })}`}
                      type="submit"
                    >
                      <span>Claim your city</span>
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClaimYourCity;
