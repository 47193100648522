import React from 'react';
import useScript from 'utils/use-script';
import useGlobalVar from 'utils/use-global-var';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import './places-autocomplete.scss';

const PlacesAutocomplete = props => {
  useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_API_KEY}&libraries=places`,
  });

  const { globalVarIsAvailable } = useGlobalVar('google');

  return (
    <>
      {globalVarIsAvailable && (
        <ReactGoogleAutocomplete
          style={{ width: 'calc(100% + 5px)' }}
          {...props}
        />
      )}
    </>
  );
};

export default PlacesAutocomplete;
