import React, { useEffect } from 'react';
import useLottie from 'utils/use-lottie';
import { useInView } from 'react-intersection-observer';
import styles from './how-it-works.module.scss';

const HowItWorks = props => {
  const [animationVisibilityRef, inView] = useInView();

  const animationOptions = { renderer: 'svg', loop: true, autoplay: false };

  const { animation: animationOne, animationRef: animationIconOne } = useLottie(
    {
      ...animationOptions,
      path: '/animations-data/how-it-works-animations/funnel.json',
    }
  );
  const { animation: animationTwo, animationRef: animationIconTwo } = useLottie(
    {
      ...animationOptions,
      path: '/animations-data/how-it-works-animations/leads.json',
    }
  );
  const {
    animation: animationThree,
    animationRef: animationIconThree,
  } = useLottie({
    ...animationOptions,
    path: '/animations-data/how-it-works-animations/marketing.json',
  });

  useEffect(() => {
    if (inView && animationOne) {
      animationOne.play();
      animationTwo.play();
      animationThree.play();
    }
    if (!inView && animationOne) {
      animationOne.pause();
      animationTwo.pause();
      animationThree.pause();
    }
  }, [inView]);

  return (
    <section className="section-default">
      <div className="container">
        <h2
          className={`heading md center ${styles.title}`}
          ref={animationVisibilityRef}
        >
          How It Works
        </h2>
        <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.icon} ref={animationIconOne} />
            <h3 className={`heading sm center ${styles.featureTitle}`}>
              Generate Lead Funnel
            </h3>
            <p className={styles.description}>
              Take a couple of minutes to fill out a few details. Our software
              will create you your very own branded lead funnel with your custom
              URL.
            </p>
          </div>
          <div className={styles.column}>
            <div className={styles.icon} ref={animationIconThree} />
            <h3 className={`heading sm center ${styles.featureTitle}`}>
              Smart Marketing
            </h3>
            <p className={styles.description}>
              Take advantage of our marketing services. Create optimized ads
              with our recommended settings for outstanding results.
            </p>
          </div>
          <div className={styles.column}>
            <div className={styles.icon} ref={animationIconTwo} />
            <h3 className={`heading sm center ${styles.featureTitle}`}>
              Get Leads
            </h3>
            <p className={styles.description}>
              When complete or even partial leads enter your lead funnel, you'll
              receive a text with their information immediately.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
